/* Home Container */
.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #333333; /* Dark text for readability */
    min-height: 100vh; /* Full height */
    flex-direction: row; /* Default layout */
    background-color: #f9f9f9; /* Light background */
  }
  
  /* Profile Section */
  .profile-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-image {
    object-fit: cover;
    border-radius: 50%;
    height: 300px;
    width: 300px;
    border: 5px solid #e0e0e0; /* Subtle border for a polished look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  /* Text Section */
  .text-section {
    flex: 2;
    padding: 20px;
  }
  
  .text-section h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #222222; /* Slightly darker for emphasis */
  }
  
  .text-section p {
    font-size: 18px;
    line-height: 1.6; /* Improved readability */
    color: #555555; /* Softer grey for less contrast */
  }
  
  /* Animated Text */
  .animated-text {
    color: #1A73E8; /* Modern blue */
    font-size: 32px;
    font-weight: bold;
    margin: 0 0 20px 0;
    animation: typing 2s steps(30, end), blink-caret 0.5s step-end infinite;
    white-space: nowrap;
    overflow: hidden;
    border-right: 0.15em solid #D93025; /* Contrasting red for the caret */
  }
  
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: #D93025; }
  }
  
  /* Call-to-Action Button */
  .cta-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #1A73E8; /* Modern blue for buttons */
    color: #FFFFFF; /* White text for contrast */
    text-decoration: none;
    border-radius: 6px; /* Slightly rounded for a modern look */
    font-size: 18px;
    font-weight: 500; /* Slightly bolder for emphasis */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effects */
  }
  
  .cta-button:hover {
    background-color: #1558B0; /* Darker blue on hover */
    transform: scale(1.05); /* Slight zoom for interactivity */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .profile-image {
      height: 250px;
      width: 250px;
      margin-bottom: 20px;
    }
  
    .home-container {
      flex-direction: column-reverse; /* Stack sections vertically */
      padding: 20px; /* Adjust padding for spacing */
    }
  
    .text-section {
      padding: 10px;
      text-align: center; /* Center align text for better mobile layout */
    }
  
    .animated-text {
      font-size: 24px; /* Adjusted for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .profile-image {
      height: 220px;
      width: 220px;
    }
  
    .animated-text {
      font-size: 20px;
    }
  
    .text-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .cta-button {
      font-size: 16px;
      padding: 10px 20px; /* Adjusted padding for smaller screens */
      margin-bottom: 20px;
    }
  }
  