.projects {
    color: #333333; /* Dark grey for text */
    background-color: #f9f9f9; /* Light background to match the overall theme */
    padding: 20px;
    margin-top: 140px;
    margin-bottom: 180px;
    font-family: 'Roboto', Arial, sans-serif; /* Modern, clean font */
    line-height: 1.8; /* Slightly increased for better readability */
    border: 1px solid #e0e0e0; /* Subtle border for definition */
    border-radius: 8px; /* Rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  }
  
  /* Headings */
  .projects h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #222222; /* Slightly darker for emphasis */
  }
  
  /* Paragraphs */
  .projects p {
    font-size: 18px;
    margin-bottom: 15px;
    color: #555555; /* Softer grey for secondary text */
  }
  
  /* List Items */
  .projects ul {
    list-style: disc; /* Classic bullet points for clarity */
    padding-left: 40px; /* Indented for better alignment */
  }
  
  .projects li {
    margin-bottom: 10px; /* Spacing between list items */
    font-size: 16px;
    color: #333333; /* Matches text color */
  }
  
  /* Links */
  .projects a {
    color: #1A73E8; /* Modern blue for links */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .projects a:hover {
    color: #D93025; /* Contrasting red for hover effect */
    text-decoration: underline; /* Underline on hover for clarity */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .projects {
      margin-top: 100px;
      margin-bottom: 100px;
      padding: 15px;
    }
  
    .projects h2 {
      font-size: 24px;
    }
  
    .projects p {
      font-size: 16px;
    }
  
    .projects ul {
      padding-left: 20px; /* Reduce indentation on smaller screens */
    }
  
    .projects li {
      font-size: 14px;
    }
  }
  