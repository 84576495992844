.resume {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: 'Roboto', Arial, sans-serif;
    line-height: 1.8;
    margin-top: 180px;
    margin-bottom: 180px;
}

.resume-header {
    text-align: center;
    margin-bottom: 40px;
}

.resume-header h1 {
    font-size: 2rem;
    color: #1A73E8;
}

.resume-header p a {
    color: #1A73E8;
    text-decoration: none;
    margin: 0 5px;
}

.resume-header p a:hover {
    text-decoration: underline;
}

.resume-section {
    margin-bottom: 40px;
}

.resume-section h2 {
    font-size: 1.5rem;
    color: #1A73E8;
    border-bottom: 2px solid #1A73E8;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.resume-section ul {
    margin: 0;
    padding-left: 20px;
}

.resume-section li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .resume {
        padding: 15px;
    }

    .resume-header h1 {
        font-size: 1.8rem;
    }

    .resume-section h2 {
        font-size: 1.3rem;
    }
}
