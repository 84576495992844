/* Header */
.header {
    background-color: #ffffff; /* Clean white background */
    color: #333333; /* Dark text for readability */
    text-align: center;
    padding: 20px 0; /* Spacious padding for visual balance */
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e0e0e0; /* Light border for separation */
  }
  
  /* Navigation List */
  .nav-list {
    list-style: none; /* Removes bullet points */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 20px; /* Consistent spacing between items */
  }
  
  /* Navigation Items */
  .nav-item {
    margin: 0; /* Removed redundant margin */
  }
  
  /* Navigation Links */
  .nav-link {
    color: #1A73E8; /* Modern blue for links */
    text-decoration: none;
    font-weight: 500; /* Slightly bold for better visibility */
    font-size: 1rem; /* Standardized font size */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth hover effects */
  }
  
  .nav-link:hover {
    color: #D93025; /* Contrasting red on hover */
    transform: scale(1.1); /* Subtle zoom for interactivity */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .header {
      padding: 15px 0; /* Slightly reduced padding on smaller screens */
    }
  
    .nav-list {
      flex-wrap: wrap; /* Allow items to wrap on smaller screens */
      gap: 15px; /* Adjust spacing for smaller layouts */
    }
  
    .nav-link {
      font-size: 0.9rem; /* Slightly smaller font for smaller screens */
    }
  }
  