/* Footer */
.footer {
    background-color: #f9f9f9; /* Light, neutral background */
    color: #333333; /* Dark text for better readability */
    text-align: center;
    padding: 20px 0; /* Increased padding for a more spacious look */
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1); /* Softer shadow for a clean finish */
    border-top: 1px solid #e0e0e0; /* Subtle border for separation */
    z-index: 1000; /* Ensures the footer stays above other elements */
  }
  
  .footer-content {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap; /* Ensures responsiveness on smaller screens */
    align-items: center;
    justify-content: space-between;
    gap: 10px; /* Spacing between elements for better layout */
  }
  
  /* Social List */
  .social-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px; /* Equal spacing between social icons */
  }
  
  .social-list li {
    margin: 0; /* Removes redundant margin */
  }
  
  .social-list a {
    color: #1A73E8; /* Modern blue for links */
    text-decoration: none;
    font-size: 1.2rem; /* Slightly larger for visibility */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth hover effects */
  }
  
  .social-list a:hover {
    color: #D93025; /* Contrasting red for hover */
    transform: scale(1.1); /* Slight zoom for interactivity */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      text-align: center;
    }
  
    .social-list {
      justify-content: center; /* Center the social icons */
    }
  }
  