/* Reset and Base Styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', Arial, sans-serif; /* Modern font for a clean look */
  background-color: #F9F9F9; /* Light, neutral background */
  color: #333333; /* Dark grey text for better contrast */
  line-height: 1.6; /* Improved text readability */
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Anchor Tags */
a {
  color: #1A73E8; /* Modern blue for links */
  text-decoration: none;
  font-weight: 500; /* Slightly bold for emphasis */
  transition: color 0.3s ease; /* Smooth hover effect */
}

a:hover {
  color: #D93025; /* Contrasting red for hover state */
}

/* Container */
.container {
  padding: 20px;
  margin: auto;
  max-width: 1200px; /* Limits content width for better readability */
}

/* Cards */
.card {
  background-color: #FFFFFF; /* Clean white background for cards */
  border: 1px solid #E0E0E0; /* Light grey border for definition */
  border-radius: 8px; /* Rounded corners for a soft, modern look */
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Modern hover effect */
}

.card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Deeper shadow on hover */
}

.card h1, .card h2, .card p {
  margin: 10px 0;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: #222222; /* Slightly darker headings for emphasis */
  margin: 15px 0;
  font-weight: 600;
}

p {
  font-size: 1rem;
  margin: 10px 0;
}

/* Buttons */
.button {
  background-color: #1A73E8; /* Modern blue */
  color: #FFFFFF; /* White text for contrast */
  border: none;
  border-radius: 4px; /* Slightly rounded corners */
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background-color: #1558B0; /* Darker blue on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* Footer */
footer {
  text-align: center;
  padding: 20px;
  background-color: #F1F1F1; /* Subtle contrast for footer */
  color: #666666; /* Muted text for footer */
  font-size: 0.9rem;
}
